import { Injectable } from '@angular/core'

declare let gtag: (...args) => void

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public email = 'hi@crispy-portraits.de'

  public profileLink = 'https://www.facebook.com/lucas.bauche'
  public instaLink = 'https://www.instagram.com/crispy.portraits/'

  public cookieState: 'not-saved' | 'denied' | 'accepted' = 'not-saved'
  public cookieDecisionMade = false

  public _isHeaderDark = false

  constructor() {
    const mailSubject = 'Shooting-Anfrage 📸'
    const mailBody = 'Hey Lucas 👋\n\nich habe Interesse an einem Shooting!\n\nIch bin...'

    this.email = `${this.email}?subject=${encodeURI(mailSubject)}&body=${encodeURI(mailBody)}`
  }

  // Cookies consent
  public initCookieState(): void {
    const stored = localStorage.getItem('cookiesAccepted')
    if (stored !== null && stored !== undefined) {
      this.cookieDecisionMade = true

      this.cookieState = stored === 'true' ? 'accepted' : 'denied'

      if (this.cookieState === 'accepted') {
        this.loadGA()
      }
    }
  }

  public acceptCookies(): void {
    this.cookieDecisionMade = true
    this.cookieState = 'accepted'
    localStorage.setItem('cookiesAccepted', 'true')
    this.loadGA()
  }

  public denyCookies(): void {
    this.cookieDecisionMade = true
    this.cookieState = 'denied'
    localStorage.setItem('cookiesAccepted', 'false')
  }

  /**
   * Inits the Google Analytics Snippet
   */
  public loadGA(): void {
    const win = window as any
    win.dataLayer = win.dataLayer || []
    gtag('js', new Date())
    gtag('config', 'G-V8DJ1PZNT8')
  }


  get isHeaderDark(): boolean {
    return this._isHeaderDark 
  }

  set isHeaderDark(val: boolean) {
    this._isHeaderDark  = val
    const headerEl = document.getElementById("header")
    if (headerEl) {
      if (this._isHeaderDark && !headerEl.classList.contains('dark')) {
        document.getElementById("header").classList.add('dark');
      } else if (!this._isHeaderDark && headerEl.classList.contains('dark')) {
        document.getElementById("header").classList.remove('dark');
      }
    }
  }

}
