<div class="page">
  <div class="section text-content">
    <h1>Impressum</h1>

    <p>Angaben gemäß § 5 TMG</p>
    <p>Lucas Ventures UG (haftungsbeschränkt)<br>
      Greifswalder Str. 29<br>
      20099 Hamburg<br>
    </p>
    <p>
      <strong>Vertreten durch:</strong><br>
      Lucas Bauche<br>
    </p>
    <p><strong>Kontakt:</strong> <br>
      Telefon: 0151 5258 2854<br>
      E-Mail: <a href="mailto:info@lucas-ventures.de">info@lucas-ventures.de</a>
    </p>
    <p><strong>Registereintrag: </strong><br>
      Eintragung im Registergericht: Hamburg<br>
      Registernummer: 126781<br>
    </p>
    <br>
    <h3>Haftungsausschluss</h3>
    <strong>Haftung für Inhalte</strong>
    <p>
      Die Inhalte der Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität
      der Inhalte kann jedoch keine Gewähr übernehmen werden. Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG für eigene
      Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich als
      Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
      Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
      diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
      Bekanntwerden von entsprechenden Rechtsverletzungen werden diese Inhalte umgehend entfernt.
    </p>
    <strong>Haftung für Links</strong>
    <p>
      Die Website kann Links zu externen Webseiten Dritter enthalten, auf deren Inhalte kein Einfluss besteht. Für diese
      fremden Inhalte kann deshalb keine Gewähr übernommen werden. Für die Inhalte der verlinkten Seiten ist stets der
      jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
      Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
      erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend
      entfert.
    </p>
    <strong>Urheberrecht</strong>
    <p>
      Die erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
      schriftlichen Zustimmung des Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
      kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden
      die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
      trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten ich um einen entsprechenden Hinweis. Bei
      Bekanntwerden von Rechtsverletzungen werdeb derartige Inhalte umgehend entfernt.
    </p>
  </div>
</div>
