<!-- Main Menu -->
<div class="page">
  <div class="section no-margin">

    <div id="header" class="header">

      <!-- Title -->
      <div class="title">
        <a routerLink="/" title="Crispy Portraits – Startseite">
          Lucas Bauche
        </a>
      </div>

      <!-- Right side info -->
      <div class="info">
        <!-- <div>
          <a [attr.href]="appService.profileLink" title="Lucas – Public Profile"><div class="me"></div> Lucas Bauche</a>
        </div> -->
        <div>
          <a href="https://www.google.de/maps/place/Hamburg/" target="_blank" title="Hamburg – Google Maps">📍 Hamburg,
            DE</a>
        </div>
        <div>
          <a [attr.href]="'mailto:' + appService.email" title="Kontakt via E-Mail">✉️ Kontakt</a>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- Router content -->
<router-outlet></router-outlet>

<!-- Footer -->
<div class="page">
  <div class="sitemap txt--body">
    <div>
      <a routerLink="/terms" title="Allgemeine Geschäftsbedingungen">AGB</a>
    </div>
    <div>
      <a routerLink="/data-policy" title="Datenschutz">Datenschutz</a>
    </div>
    <div>
      <a [attr.href]="'mailto:' + appService.email" title="Kontakt">Kontakt</a>
    </div>
    <div>
      <a routerLink="/imprint" title="Impressum">Impressum</a>
    </div>
  </div>
  <div class="footer txt--center p--m">
    Copyright {{ today.getFullYear() }} – Lucas Ventures UG (haftungsbeschränkt)
  </div>
</div>

<!-- Cookie banner -->
<div class="cookie-banner" *ngIf="!appService.cookieDecisionMade" [@slideIn]>
  <div class="title">Die Seite verwendet Cookies 🍪</div>
  <div class="text">
    Die Seitet verarbeitet Informationen über deinen Besuch unter Einsatz von Cookies, um die Leistung der Website zu
    verbessern.
    Mehr Infos dazu gibt es <a title="Datenschutz und Cookierichtlinie" routerLink="/data-policy">hier</a>.
  </div>
  <div class="txt--right">
    <span class="clear-button" (click)="denyCookies()">Ablehnen</span>
    <button class="button purple small" (click)="acceptCookies()">Geht klar!</button>
  </div>

</div>
