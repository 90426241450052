import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [
    trigger('expand', [
      transition(':leave', [
        style({ height: '*', overflow: 'hidden' }),
        animate('.2s ease-out', style({ height: '0', overflow: 'hidden' })),
      ]),
      transition(':enter', [
        style({ height: '0', overflow: 'hidden' }),
        animate('.2s ease-out', style({ height: '*' })),
      ]),
    ]),
  ],
})
export class MainPageComponent implements OnInit {
  faq = [
    {
      question: 'An welchen Tagen und Zeiten finden die Shootings statt?',
      answer: `<p>Das können wir flexibel und individuell abstimmen. Wochentag, Wochenende, morgens, abends – bei mir ist eigentlich alles möglich.</p>
      <p>Generell bieten sich für Outdoor-Shootings eher die Morgen- oder Abendstunden an, aber auch das ist abhängig vom Wetter. Sonnig muss es für ein gelungenes Shooting nicht sein.</p>`,
      expanded: false,
    },
    {
      question: 'Wo findet das Shooting statt?',
      answer: `<p>Die Shootings finden drinnen oder draußen statt, je nach dem was wir vor haben.</p><p>Drinnen eignet sich vor allem für Cosy-Shots oder wenn bestimmte Möbel eine Rolle spielen (das Piano?). Indoor-Shootings machen wir entweder bei dir oder einer von dir gestellten Location. Ein eigenes Studio habe ich nicht.</p>
      <p>Die meisten Shootings mache ich draußen. Hamburg hat viele schöne Ecken. Im Grünen, im urbanen Hafen, der quirkigen Schanze oder im urbanen St. Georg.</p>`,
      expanded: false,
    },
    {
      question: 'Ich bin noch recht unerfahren vor der Kamera, macht das was?',
      answer: `<p>Nein, das ist überhaupt kein Problem. Jeder fängt irgendwann mal an.</p><p>Bring eine ordentliche Portion gute Laune mit und hab Spaß am Ausprobieren.</p><p>Ich gebe dir gerne viele Tipps und guide dich, so dass wir am Ende auf jeden Fall tolle Fotos haben werden.</p>`,
      expanded: false,
    },
    {
      question: 'Kann ich jemanden zum Shooting mitbringen?',
      answer: `<p>Klar, gar kein Problem. Wir können auch gerne auch gemeinsame Shooting arrangieren.</p><p>Du solltest nur darauf achten, dass du dich nicht zu sehr ablenken lässt.</p>`,
      expanded: false,
    },
    {
      question: 'Was sollte ich zum Shooting mitbringen/anziehen?',
      answer: `<p>Wenn du spezielle Ideen oder Wünsche hast, bin ich da gerne offen für.</p><p>Generell empfehle ich mehrere Oberteile mitzubringen (3-5), am liebsten engere, schlichte, nicht zu farbenfrohe. Bring auch gerne ein paar Optionen für deine Haare mit. So können wir eine große Vielfalt an unterschiedlichen Looks erzeugen.</p>
      <p>Für das Make-up empfehle ich: Weniger ist mehr. Am liebsten kaum Makeup, aber eine gepflegte, eingecremte Haut. Die Lippen etwas feucht und leicht glänzend halten.</p>`,
      expanded: false,
    },
    {
      question: 'Was ist, wenn mir die Fotos nicht gefallen?',
      answer:
        '<p>Das ist noch nie passiert.</p><p>Sollte tatsächlich irgendwas ins Wasser fallen oder wir partout keine schönen Fotos haben, machen wir das Shooting einfach erneut – auf meine Kosten.</p>',
      expanded: false,
    },
    {
      question: 'Wie läuft die Bezahlung/Buchung?',
      answer: `<p>Wie du vielleicht festgestellt hast, habe ich kein Online-Buchungsportal.</p><p>Wir vereinbaren gemeinsam was wir machen wollen und zu welchen Konditionen. Du bekommst dann einen entsprechendes Angebot von mir. Meine allgemeinen Geschäftsbedingungen (AGB) findest du <a class="txt--underline" title="AGB" href="/terms">hier</a>.</p><p>Dirket nach dem Shooting bekommst du eine reguläre Rechnung (digital) von mir. Die Bezahlung läuft dann via Paypal oder Überweisung. </p>`,
      expanded: false,
    },
    {
      question: 'Kann ich dich auch für ein anderes Shooting buchen?',
      answer: `<p>Klar – was schwebt dir vor? Hochtzeit? Business? Fashion?</p><p>Schreib mir einfach und dann besprechen wir das persönlich.</p>`,
      expanded: false,
    },
  ];

  references = [
    {
      name: 'Luisa',
      photo: '1.jpg',
      quote:
        '“Bestes Geschenk! Es war mein erstes Profi-Shooting und Lucas hat es wirklich angenehm gestaltet und viele tolle Tipps gebeben.“',
      date: '18.03.2021',
    },
    {
      name: 'Emma',
      photo: '3.jpg',
      quote:
        '“Lucas ist ein lustiger Zeitgenosse der sein Handwerk wirklich beherrscht.“',
      date: '21.03.2021',
    },
    {
      name: 'Lina',
      photo: '4.jpg',
      quote:
        '“Am Ende waren doch fast 2h am Shooten und die Fotos sind soo soo schön geworden!“',
      date: '05.05.2021',
    },
  ];

  shootingSteps = [
    {
      number: '1',
      title: 'Abstimmung',
      description:
        'Gemeinsam stimmen wir ab, was für ein Setting es sein soll, wann und wo wir shooten, usw.',
      image: 'step1.svg',
    },
    {
      number: '2',
      title: 'Shooting',
      description:
        'Das Shooting selbst dauert ca. 1-2h. Spielerisch probieren wir einiges aus und schießen 500+ Fotos.',
      image: 'step2.svg',
    },
    {
      number: '3',
      title: 'Foto-Auswahl',
      description:
        'Meist noch am selben Tag mache ich eine Vorauswahl und sie dir online zur Verfügung. Du wählst deine Top-10 aus.',
      image: 'step3.svg',
    },
    {
      number: '4',
      title: 'Profi-Retusche',
      description:
        'All 10 bekommen eine kleine Retusche und Optimierung. Eines bekommt eine professionelle Beauty-Retusche mittels Photoshop.',
      image: 'step4.svg',
    },
    {
      number: '5',
      title: 'Übergabe',
      description:
        'Die 10+1 Fotos stelle ich dir in voller Auflösung zum Download bereit. Du kannst sie jetzt beliebig sharen, veröffentlichen, drucken, usw.',
      image: 'step5.svg',
    },
    {
      number: '6',
      title: 'Insta-Feature',
      description:
        'Ich feature dich gerne auf meinem Insta-Account mit einer Verlinkung zu deinem Profil (optional).',
      image: 'step6.svg',
    },
  ];

  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.appService.isHeaderDark = false;
  }

  cpaClicked(): void {
    window.open('mailto:' + this.appService.email, '_blank');
  }
}
