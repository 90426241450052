<!-- HERO -->
<div class="hero-wrapper">
  <div class="hero-text">

    <h1 class="👻">Portrait Fashion Beatuy Fotograf Photograph Hamburg Kostenlost TFP 🙌</h1>
    <a [attr.href]="appService.instaLink" target="_blank" title="Crispy Portraits – Instagram">
      <picture loading="lazy">
        <img src="./assets/images/hero-text.svg" alt="Portrait Fotograf aus Hamburg">
      </picture>
    </a>
  </div>
</div>


<div class="page">

  <!-- ME -->
  <div class="section">
    <div class="txt--center">
      <picture loading="lazy">
        <img src="./assets/images/types.svg" alt="Portrait, Beauty, Fashion Photography">
      </picture>
    </div>
    <h2 class="mb--m mt--xs">Models gesucht für Portfolio-Aufbau 🙌</h2>

    <div class="me-wrapper split">
      <div class="split-1 txt--center">
        <picture loading="lazy">
          <img src="./assets/images/me-2.svg" alt="It's me – Lucas">
        </picture>
      </div>
      <div class="split-1 txt--body p--m">
        <p>
          Hi, ich bin Lucas, Photograph aus Hamburg St. Georg. 👋
        </p>
        <p>
          Ich suche aktuell nach weiblichen Models zum Aufbau meines Portfolios. Dazu biete ich <strong>kostenlose
            Shootings auf TFP-Basis</strong>. Du bist (Newcomer-) Model und ebenfalls im Aufbau deines Portfolios oder
          Insta-Accounts? It’s a Match! 🤗
        </p>
        <p>
          Ich habe eine kleine Studio-Möglichkeit und shoote sonst Outdoor in guten Available-Light-Situationen.
        </p>
        <p>
          Meine Fotos erfahren häufig eine dezente, aber professionelle Photoshop Retouche für den letzten Schliff.
        </p>
        <p>
          Als Model erhälst du meist 50+ Fotos pro Shooting.
        </p>
      </div>
    </div>
  </div>


  <!-- CTA -->
  <div class="section cta-bg">

    <div class="cta">
      <button class="button purple" (click)="cpaClicked()">
        Shooting anfragen
      </button>
    </div>
  </div>

  <!-- THANK YOU -->
  <div class="section txt--center">
    <a [attr.href]="appService.instaLink" target="_blank" title="Crispy Portraits – Instagram">
      <picture loading="lazy">
        <img src="./assets/images/insta.svg" alt="Crispy Portraits" />
      </picture>
    </a>
  </div>
</div>
