import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-data-protection-page',
  templateUrl: './data-protection-page.component.html',
  styleUrls: ['./data-protection-page.component.scss'],
})
export class DataProtectionPageComponent implements OnInit {
  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.appService.isHeaderDark = false;
  }
}
