import { trigger, transition, style, animate } from '@angular/animations';
import { OnInit, Component } from '@angular/core';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':leave', [
        style({ transform: 'translate(-50%,0%)' }),
        animate('.3s ease-out', style({ transform: 'translate(-50%, 150%)' })),
      ]),
      transition(':enter', [
        style({ transform: 'translate(-50%, 150%)' }),
        animate('.3s ease-out', style({ transform: 'translate(-50%,0%)' })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  today: Date = new Date();
  isDark = true;

  constructor(public appService: AppService) {}

  ngOnInit() {
    this.appService.initCookieState();
  }

  denyCookies(): void {
    this.appService.denyCookies();
  }

  acceptCookies(): void {
    this.appService.acceptCookies();
  }
}
