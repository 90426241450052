<div class="page">
  <div class="section text-content">
    <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>

    <h3>I. Allgemeines</h3>
    <p>1. Die nachfolgenden AGB gelten für alle dem Fotografen erteilten Aufträge. Sie gelten als vereinbart, wenn ihnen
      nicht umgehend widersprochen wird.</p>
    <p>2. Werke sind vom Fotografen hergestellten Lichtbilder und andere grafische Werke, bewegt und
      unbewegt, gleich in welcher technischen Form oder in welchem Medium sie erstellt wurden oder
      vorliegen.</p>

    <h3>II. Urheberrecht und Nutzungsrechte</h3>
    <p>1. Dem Fotografen steht das Urheberrecht an den Werken nach Maßgabe des
      Urheberrechtsgesetzes zu.</p>
    <p>2. Die vom Fotografen hergestellten Werke sind grundsätzlich nur für den eigenen Gebrauch des
      Auftraggebers bestimmt.</p>
    <p>3. Überträgt der Fotograf Nutzungsrechte an seinen Werken, ist jeweils nur das einfache
      Nutzungsrecht übertragen, sofern nicht ausdrücklich etwas anderes vereinbart wurde. Eine
      Weitergabe von Nutzungsrechten bedarf der besonderen Vereinbarung. Nutzungsrechte werden nur
      an den Werken übertragen, die der Auftraggeber als vertragsgemäß abnimmt, nicht an Werken die
      nur zur Sichtung oder Auswahl überlassen werden.</p>
    <p>4. Die Nutzungsrechte gehen erst über nach vollständiger Bezahlung aller dem Fotografen aus der
      Geschäftsbeziehung zustehenden Forderungen.</p>
    <p>5. Der Auftraggeber darf die Lichtbilder zu privaten Zwecken nutzen und auch vervielfältigen.</p>
    <p>6. Bei der Verwertung der Lichtbilder ist die Namensnennung pflicht. Der Fotograf ist bei jeder Veröffentlichung
      entweder direkt unter dem Bild oder im Impressum anzugeben, sofern nichts anderes vereinbart wurde.</p>
    <p>7. Der Fotograf ist nicht verpflichtet Datenträger an den Auftraggeber herauszugeben, wenn dies
      nicht ausdrücklich schriftlich vereinbart wurde.</p>
    <p>8. Ungeachtet der übertragenen Nutzungsrechte bleibt der Fotograf berechtigt, die Werke im
      Rahmen der Eigenwerbung zu verwenden. (z.B. Webseite, Blogs, Facebook, Instagram, etc.) sofern
      nichts anderes vereinbart wurde.</p>
    <p>9. Die Rohdaten (RAW) verbleiben beim Fotografen.</p>

    <h3>III. Vergütung, Eigentumsvorbehalt, Aufbewahrung</h3>
    <p>1. Kostenvoranschläge des Fotografen sind unverbindlich. An von ihm erstellten
      Kostenvoranschlägen, Zeichnungen, Grafiken, Plänen und anderen Unterlagen behält sich der
      Fotograf sämtliche Nutzungs- und Verbreitungsrechte vor. Sie dürfen Dritten nicht zugänglich
      gemacht werden. Bei Nichterteilung des Auftrags sind diese unverzüglich zu löschen.</p>
    <p>2. Für die Herstellung der Werke wird ein Honorar als Stundensatz, Tagessatz oder vereinbarte
      Pauschale vereinbart; Nebenkosten (Reisekosten, Modellhonorare, Spesen, Requisiten, Labor- und
      Materialkosten, Studiomieten, Datenhandlingskosten, etc.) sind vom Auftraggeber zu tragen.
    </p>
    <p>3. Bis zur vollständigen Bezahlung aller dem Fotografen aus der Geschäftsbeziehung zustehenden
      Forderungen bleiben die gelieferten Werke und Datenträger Eigentum des Fotografen.</p>
    <p>4. Im Falle eines so genannten TFP-Shootings („Time for Pictures“), erhalten sowohl der Fotograf als auch das
      Model / die Modelle kein Honorar für ihre Tätigkeit. Die nähere Ausgestaltung eines solchen gesondert vereinbarten
      TFP-Shootings (inklusive aller Rechte und Pflichten) wird in einem auf den jeweiligen Einzelfall zugeschnittenen
      <a href="https://docs.google.com/document/d/1-fDGx5XWGs9Fz6f3lIH_dLDuQ7K0POiYUtRMAYp1A04/edit?usp=sharing" target="_blank" title="TFP Vertrag">TFP-Vertrag</a> geregelt. Die übrigen hier aufgeführten AGB gelten für TFP-Verträge sinngemäß insofern nicht im
      TFP-Vertrag andere Regelungen getroffen worden sind.</p>
    <p>5. Hat der Auftraggeber dem Fotografen keine ausdrücklichen Weisungen hinsichtlich der
      Gestaltung der Lichtbilder gegeben, so sind Reklamationen bezüglich der Bildauffassung sowie der
      künstlerisch-technischen Gestaltung ausgeschlossen. Wünscht der Auftraggeber während oder nach
      der Aufnahmeproduktion Änderungen, so hat er die Mehrkosten zu tragen.</p>
    <p>6. Der Fotograf ist nicht verpflichtet digitale Daten der angefertigten Werke
      zu speichern, nachdem diese vom Auftraggeber abgenommen und diesem in vertragsgemäßer Weise
      zur Verfügung gestellt worden sind.</p>
    <p>7. Eine 100%ige Anpassung von Lichtbildern an beim Auftraggeber oder beim Fotografen bereits
      vorhandene Bilder (z.B. Kontrast, Farbe, Helligkeit) kann nicht garantiert werden.</p>

    <h3>IV. Haftung</h3>
    <p>Für die Verletzung von Pflichten, die nicht in unmittelbarem Zusammenhang mit wesentlichen Vertragspflichten
      stehen, haftet der Fotograf für sich und seine Erfüllungsgehilfen nur bei Vorsatz und grober Fahrlässigkeit. Er
      haftet ferner für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit sowie aus der Verletzung
      wesentlicher Vertragspflichten, die er oder seine Erfüllungsgehilfen durch schuldhafte Pflichtverletzungen
      herbeigeführt haben. Für Schäden an Aufnahmeobjekten, Vorlagen, Filmen, Displays, Layouts, Negativen oder Daten
      haftet der Fotograf - wenn nichts anderes vereinbart wurde - nur bei Vorsatz und grober Fahrlässigkeit.</p>

    <h3>V. Leistungsstörung, Ausfallhonorar</h3>
    <p>1. Zeitpläne und Liefertermine sind nur bindend, wenn sie von dem Fotografen ausdrücklich als
      bindend bestätigt worden sind.</p>
    <p>2. Wird die für die Durchführung des Auftrages vorgesehene Zeit aus Gründen, die der Fotograf
      nicht zu vertreten hat, wesentlich überschritten, so erhöht sich das Honorar des Fotografen, sofern
      ein Pauschalpreis vereinbart war, entsprechend.
    </p>
    <p>3. Ist ein Zeithonorar vereinbart, erhält der Fotograf auch für die Wartezeit den vereinbarten Stunden- oder
      Tagessatz.</p>

    <!-- <p>3. a) Unterbleibt bei einer Bildveröffentlichung durch den Auftraggeber die Benennung des
      Bildautors, so hat der Auftraggeber einen Schadensersatz in Höhe des vereinbarten Entgelts zu
      zahlen, ist keines vereinbart, in Höhe des üblichen Nutzungshonorars, mindestens jedoch 200 € pro
      Bild und Einzelfall.</p>
    <p>b) Bei unberechtigter Nutzung, Veränderung, Umgestaltung oder Weitergabe eines Werkes durch
      den Auftraggeber hat dieser einen Schadensersatz in Höhe des Doppelten des für diese Nutzung
      vereinbarten Entgelts zu zahlen, ist keines vereinbart, dass Doppelte des üblichen
      Nutzungshonorars, mindestens jedoch 200,00 € pro Werk und Einzelfall.</p>
    <p>c) Kündigt der Auftraggeber den Auftrag vor Beginn der Ausführung, ohne das den Fotografen
      hierfür ein Verschulden trifft, so hat er dem Fotografen 10 % der Gesamtauftragssumme als
      Schadensersatz zu zahlen.</p>
    <p>d) Dem Fotografen bleibt zu a) – c) die Geltendmachung eines weitergehenden Schadens
      vorbehalten. Dem Auftraggeber bleibt zu a) – c) der Nachweis eines geringeren tatsächlichen
      Schadens vorbehalten.</p> -->

    <!-- Ist ja schon auf der anderen Seite -->
    <!-- <h3>VII. Datenschutz</h3>
    <p>Die dem Fotografen mitgeteilten Daten des Auftraggebers werden elektronisch gespeichert, soweit
      dies zur ordnungsgemäßen Abwicklung der Geschäftsverbindung notwendig ist. Der Fotograf
      verpflichtet sich, alle ihm im Rahmen des Auftrages bekannt gewordenen Informationen vertraulich
      zu behandeln.</p> -->

    <h3>VI. Schlussbestimmungen</h3>
    <p>Sind beide Vertragsparteien Kaufleute oder der Auftraggeber eine juristische Person des
      öffentlichen Rechts oder ein öffentlich rechtliches Sondervermögen, so ist der Geschäftssitz des
      Fotografen als Gerichtsstand vereinbart.</p>

    <h3>VII. Sonstiges</h3>
    <p>Der Fotograf weist darauf hin, dass der Auftraggeber möglicherweise für die gezahlte Vergütung
      Beiträge zur Künstlersozialversicherung abführen muss. Hierüber wird sich der Auftraggeber selbst
      kundig machen.</p>

  </div>
</div>
