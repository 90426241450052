import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-terms-and-conditions-page',
  templateUrl: './terms-and-conditions-page.component.html',
  styleUrls: ['./terms-and-conditions-page.component.scss'],
})
export class TermsAndConditionsPageComponent implements OnInit {
  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.appService.isHeaderDark = false;
  }
}
