import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-short-page',
  templateUrl: './short-page.component.html',
  styleUrls: ['./short-page.component.scss'],
})
export class ShortPageComponent implements OnInit {
  constructor(public appService: AppService, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.appService.isHeaderDark = true;
    this.ref.detectChanges();
  }

  cpaClicked(): void {
    window.open('mailto:' + this.appService.email, '_blank');
  }
}
