
<div class="page">

    <!-- HERO -->
    <div class="section hero-wrapper">

        <div class="hero-info">
            <div class="hero-text">
                Premium<br> Portraits<br> von <span class="txt--underline">Dir</span>
            </div>

            <div class="hero-cta">
                <button class="button red" (click)="cpaClicked()">
                    Jetzt Shooting anfragen
                </button>
            </div>
        </div>
        <div class="hero-image">
            <picture loading="lazy">
                <img src="/assets/photos/4.jpg" alt="Crispy Portrait">
            </picture>
        </div>
    </div>


    <!-- GALLERY -->
    <div class="section no-margin">
        <h2>Portraits-Shootings, die Augen leuchten lassen</h2>
        <div class="txt--subline">
            für mehr Likes, Follower, Jobs, Deals oder Date-Matches: Step-up your Game
        </div>
    </div>

        <!-- Images -->
    <div class="galery-wrapper">
        <div class="galery">
            <div class="galery-image" *ngFor="let item of ['1.jpg','2.jpg','3.jpg','4.jpg','5.jpg']"
                 [style.backgroundImage]="'url(/assets/photos/' + item + ')'">
            </div>
        </div>
    </div>


    <!-- ME -->
    <div class="section">
        <h2 class="mb--xl">👋 Ich bin Lucas, Portrait-Photograph aus Hamburg</h2>
        
        <div class="me-wrapper split">
            <div class="split-1 txt--center">
                <picture loading="lazy">
                    <img src="/assets/images/me.svg" alt="It's me – Lucas">
                </picture>
            </div>
            <div class="split-1 txt--center txt--body p--m">
                <p>
                    Super cool, dass du auf meiner Seite vorbeischaust! Hauptberuflich bin ich eigentlich IT-Unternehmer, aber für meine Leideschaft der Fotografie finde ich immer etwas Zeit. Ich liebe es ausdruckstarke Fotos zu machen und in der Retusche das Beste herauszuholen.
                </p>
                <p>
                    Ich bin ein lustiger, charmanter Zeitgenosse und gestalte meine Shootings ganz entspannt und flexibel. Egal ob Profi oder Neuling, der Spaß steht bei mir im Vordergrund. Ich helfe dir, dass wir dich optimal in Szene setzen und gebe dir gerne reichlich Tipps.
                </p>
                <p>
                    <a [attr.href]="appService.instaLink" target="_blank">
                        <img src="/assets/images/insta-link.svg" alt="Crispy Portraits – Instagram"/>
                    </a>
                </p>
            </div>
        </div>
    </div>


    <!-- OFFER SUMMARY -->
    <div class="section">
        <h2>1h Portrait-Shooting + 1A Beauty-Retusche</h2>
        <div class="txt--subline mb--l">
            Spaß und bomben Fotos garantiert
        </div>

        <div class="split">
            <div class="split-1">
                <div class="radius--l m--m p--m bg--nude">
                    image 1
                </div>
                <div class="txt--body txt--center">
                    Für erfahrene und unerfahrene Models
                </div>
                <div class="txt--mini txt--green txt--center">
                    Noch nie ein Shooting gehabt? Macht nichts!
                </div>
            </div>
            <div class="split-1">
                <div class="radius--l m--m p--m bg--nude">
                    image 2
                </div>
                <div class="txt--body txt--center">
                    In Hamburg und Umgebung
                </div>
                <div class="txt--mini txt--green txt--center">
                    Weil da bin ich halt 🤷‍♂️
                </div>
            </div>
            <div class="split-1">
                <div class="radius--l m--m p--m bg--nude">
                    image 3
                </div>
                <div class="txt--body txt--center">
                    Spaß und bomben Fotos garantiert
                </div>
                <div class="txt--mini txt--green txt--center">
                    Aber sowas von versprochen! 🤞
                </div>
            </div>
        </div>
    </div>


    <!-- SHOOTING DESCRIPTION -->
    <div class="section">
        <h2 class="mb--l">So läuft unser Shooting ab</h2>

        <div class="workflow-step" *ngFor="let step of shootingSteps">
            <div class="number">
                <!-- Number -->
                <div class="square">
                    {{ step.number }}
                </div>
            </div>
            <div class="text">
                <!-- description -->
                <h3>{{ step.title }}</h3>
                <div>{{ step.description }}</div>
            </div>
            <div class="image">
                <!-- image -->
                <picture loading="lazy">
                    <img [attr.srcset]="'assets/images/' + step.image" [attr.alt]="step.title">
                </picture>
            </div>
        </div>
    </div>


    <!-- PRICING -->
    <div class="section">
        <h2 class="mb--l">Preise</h2>

        <div class="pricing-wrapper">
            <!-- NORMAL -->
            <div class="pricing-box">
                <h3 class="txt--center">Portrait-Shooting +<br>Beauty-Retusche</h3>
                <div class="info">
                    <div class="p--s">👉 1h Shooting</div>
                    <div class="p--s">👉 10 Fotos + 1 Beauty-Retusche</div>
                    <div class="p--s">👉 Jede weitere Retusche: 50 EUR</div>
                </div>
                <div class="price">
                    <div class="number">EUR 250</div>
                    <div class="txt--mini txt--green">inkl. 19% MwSt.</div>
                </div>
            </div>

            <!-- FREE -->
            <div class="pricing-box red">
                <h3 class="txt--center">NEU<br/>Kooperations-Deal: Shooting + Retusche</h3>
                <div class="info">
                    <div class="p--s">👉 1h Shooting</div>
                    <div class="p--s">👉 Alle Fotos</div>
                    <div class="p--s">👉 Min. 1 Beauty-Retusche</div>
                </div>
                <div class="price">
                    <div class="number">FREE*</div>
                    <div class="txt--mini">* als <a title="TFP Vertrag" href="https://docs.google.com/document/d/1-fDGx5XWGs9Fz6f3lIH_dLDuQ7K0POiYUtRMAYp1A04/edit?usp=sharing">TFP oder Influencer-Kooperation</a></div>
                </div>
            </div>
        </div>
    </div>


    <!-- REFERENCES -->
    <div class="section">
        <h2 class="mb--l">Wie es anderen gefallen hat</h2>

        <div class="reference-wrapper">
            <div class="reference-box" *ngFor="let item of references">
                <div class="box">
                    <div class="name">{{ item.name }}</div>
                    <div class="image" [style.backgroundImage]="'url(/assets/photos/' + item.photo + ')'">
                    </div>
                    <div class="quote">
                        {{ item.quote }}
                    </div>
                </div>
                <div class="rating">
                    <div class="stars">
                        <img src="/assets/images/5-stars.svg" alt="5 stars rating">
                    </div>
                    <div class="txt--tiny txt--green">Shooting {{ item.date }}</div>
                </div>
            </div>
        </div>
    </div>


    <!-- CTA -->
    <div class="section cta-bg">
        <h2 class="mb--l">Jetzt Shooting anfragen</h2>
        
        <div class="cta">
            <button class="button red" (click)="cpaClicked()">
                Unverbindlich Shooting anfragen
            </button>
        </div>
    </div>


    <!-- FAQ -->
    <div class="section">
        <h2 class="mb--l">Häufig gestellte Fragen</h2>

        <!-- Questions -->
        <div class="faq" *ngFor="let item of faq">
            <div class="faq-question" (click)="item.expanded = !item.expanded">
                <h3 class="m--0">{{ item.question }}</h3>
                <img *ngIf="!item.expanded" src="/assets/images/add.svg">
                <img *ngIf="item.expanded" src="/assets/images/chevron.svg">
            </div>
            <div *ngIf="item.expanded" [@expand] [innerHTML]="item.answer">
            </div>
        </div>
        
        <div class="txt--mini txt--green txt--center">
            noch Fragen offen geblieben? <a class="txt--green" title="E-Mail Kontakt" [attr.href]="'mailto:' + appService.email">Schreib mir gerne ✉️</a>
        </div>
    </div>


    <!-- THANK YOU -->
    <div class="section txt--center">
        
        <picture loading="lazy">
            <img src="/assets/images/thank-you.svg" alt="Thank you!"/>
        </picture>

        <div class="txt--h3 m--l">
            Danke für’s Vorbeischauen – you rock! 🤘️
        </div>
        <a [attr.href]="appService.instaLink" target="_blank" title="Crispy Portraits – Instagram">
            <img style="margin-left: 90px;" src="/assets/images/insta-link-with-arrow.svg" alt="Crispy Portraits – Instagram"/>
        </a>
    </div>

</div>