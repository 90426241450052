import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ImprintPageComponent } from './pages/imprint-page/imprint-page.component';
import { DataProtectionPageComponent } from './pages/data-protection-page/data-protection-page.component';
import { TermsAndConditionsPageComponent } from './pages/terms-and-conditions-page/terms-and-conditions-page.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShortPageComponent } from './pages/short-page/short-page.component';

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    ImprintPageComponent,
    DataProtectionPageComponent,
    TermsAndConditionsPageComponent,
    ShortPageComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
